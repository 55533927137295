import { SET_NOTE_OPEN, SET_LAYOUT_VISIBLE, SET_IS_FULLSCREEN } from '../actions/appSettingAction';

const initState = {
	isNoteOpen: false,
	layoutVisible: true,
	isFullscreen: false,
};

const appSettingReducer = (state = initState, action = {}) => {
	switch (action.type) {
		case SET_NOTE_OPEN:
			return { ...state, isNoteOpen: action.isOpen };
		case SET_LAYOUT_VISIBLE:
			return { ...state, layoutVisible: action.layoutVisible };
		case SET_IS_FULLSCREEN:
			return { ...state, isFullscreen: action.isFullscreen };

		default:
			return state;
	}
};

export default appSettingReducer;
