import { ApolloClient, InMemoryCache, HttpLink, split, from } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from 'apollo-link-context';
import cookies from 'utils/cookie';
import { store } from 'redux/configure-store';
import { refreshUser } from 'redux/actions/userActions';
import { UNAUTHENTICATED } from 'redux/actions/authAction';

let GRAPHQL_HOST = null;
let GRAPHQL_PROTOCOL = null;
let GRAPHQL_WEBSOCKET_PROTOCOL = null;

if (!process.env.STORYBOOK_FLAG) {
	const GRAPHQL_URL = new URL(process.env.REACT_APP_GRAPHQL);
	GRAPHQL_HOST = GRAPHQL_URL?.host;
	GRAPHQL_PROTOCOL = GRAPHQL_URL?.protocol;
	GRAPHQL_WEBSOCKET_PROTOCOL = GRAPHQL_PROTOCOL === 'http:' ? 'ws:' : 'wss:';
} else {
	GRAPHQL_HOST = `dev-gqlv2.nexpie.io`;
}

const checkAuthorization = setContext(async () => {
	const access_token = await cookies.get('access_token');
	const user_id = await cookies.get('user_id');

	if (access_token && !user_id) {
		return store.dispatch(refreshUser.request(access_token));
	}
	if (!access_token && !user_id) {
		return setTimeout(() => {
			store.dispatch({ type: UNAUTHENTICATED });
		}, 15000);
	}
});

const setAuthorizationLink = setContext(async () => {
	const access_token = await cookies.get('access_token');
	return {
		headers: {
			authorization: `Bearer ${access_token}`,
		},
	};
});

const httpLink = new HttpLink({ uri: `${GRAPHQL_PROTOCOL}//${GRAPHQL_HOST}` });

const wsLink = new WebSocketLink({
	uri: `${GRAPHQL_WEBSOCKET_PROTOCOL}//${GRAPHQL_HOST}/graphql`,
	options: {
		lazy: true,
		reconnect: true,
		connectionParams: async () => {
			const access_token = await cookies.get('access_token');
			return { authorization: `Bearer ${access_token}` };
		},
	},
});

const link = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
	},
	wsLink,
	from([checkAuthorization, setAuthorizationLink, httpLink])
);

const client = new ApolloClient({
	link: link,
	cache: new InMemoryCache(),
});

const apolloQuery = async (query, variables) => {
	return await client.query({
		query,
		variables,
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
		context: { timeout: 30000 },
	});
};

const apolloMutation = async (mutation, variables) => {
	return await client.mutate({
		mutation,
		variables,
		errorPolicy: 'all',
		fetchPolicy: 'no-cache',
	});
};

export { client, apolloQuery, apolloMutation };
