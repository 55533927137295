import { AUTHENTICATED, UNAUTHENTICATED } from '../actions/authAction';

let initState = {
	authenticated: false,
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return { ...state, authenticated: true };
		case UNAUTHENTICATED:
			return { ...state, authenticated: false };

		default:
			return state;
	}
};

export default authReducer;
