import 'antd/dist/antd.less';
import './styles/styles.scss';

import React from 'react';
import history from 'utils/history';
import { client } from 'utils/apollo';
import { Provider } from 'react-redux';
import { store } from 'redux/configure-store';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import AppLoading from 'pages/public/AppLoading';
import ErrorBoundary from 'pages/public/ErrorBoundary';
import { ConnectedRouter } from 'connected-react-router';

const AppRouter = React.lazy(() => import('./router'));

function App() {
	return (
		<Provider store={store}>
			<ApolloProvider client={client}>
				<ConnectedRouter history={history}>
					<CookiesProvider>
						<ErrorBoundary>
							<AppLoading>
								<AppRouter />
							</AppLoading>
						</ErrorBoundary>
					</CookiesProvider>
				</ConnectedRouter>
			</ApolloProvider>
		</Provider>
	);
}

export default App;
