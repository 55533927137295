import { gql } from '@apollo/client';

const DEVICE_FRAGMENT = gql`
	fragment deviceInfo on Device {
		alias
		createdtime
		description

    	deviceid

		groupid
		groupname
		projectid
		status
		tag
		tags
		hashtag
	}
`;

const DEVICE_LIST_STATUS_FRAGMENT = gql`
	fragment deviceStatusInfo on Device {
		deviceid
		status
	}
`;

const DEVICE_WITH_TOKEN_FRAGMENT = gql`
	fragment deviceWithToken on DeviceWithToken {
		alias
		createdtime
		description

    	deviceid
    	devicetoken
    	devicesecret    	

		groupid
		groupname
		projectid
		status
		tag
		tags
	}
`;

const DEVICE_TOKEN_FRAGMENT = gql`
	fragment deviceToken on DeviceToken {
		deviceid
		tokencode
		iat
		nbf
		exp
		expireIn
	}
`;

const DEVICE_STATUS_FRAGMENT = gql`
	fragment result on Result {
		code
		text
	}
`;

const SHADOW_FRAGMENT = gql`
	fragment shadow on Shadow {
		deviceid
		data
		rev
		modified
	}
`;

const SCHEMA_FRAGMENT = gql`
	fragment schema on Schema {
		deviceid
		value
	}
`;

const TRIGGER_FRAGMENT = gql`
	fragment trigger on Trigger {
		triggerid
		triggername
		type
		event
		condition
		action
		context {
			key
			value
		}
		enabled
	}
`;

const QUERY_DEVICE_LIST = gql`
	query _deviceList($filter: DeviceFilterKey, $limit: Int, $offset: Int) {
		deviceList(filter: $filter, limit: $limit, offset: $offset, orderBy: createdtime, direction: DESC) {
			...deviceInfo
		}
	}
	${DEVICE_FRAGMENT}
`;

const QUERY_DEVICE_LIST_STATUS = gql`
	query _deviceList($filter: DeviceFilterKey, $limit: Int, $offset: Int) {
		deviceList(filter: $filter, limit: $limit, offset: $offset, orderBy: createdtime, direction: DESC) {
			...deviceStatusInfo
		}
	}
	${DEVICE_LIST_STATUS_FRAGMENT}
`;

const QUERY_DEVICE = gql`
	query _device($deviceid: String!) {
		device(deviceid: $deviceid) {
			...deviceWithToken
		}
	}
	${DEVICE_WITH_TOKEN_FRAGMENT}
`;

const QUERY_DEVICE_WITH_DEVICE_TOKEN = gql`
	query _deviceWithDeviceToken($deviceid: String!) {
		device(deviceid: $deviceid) {
			...deviceWithToken
		}
		deviceToken(deviceid: $deviceid) {
			...deviceToken
		}
	}
	${DEVICE_WITH_TOKEN_FRAGMENT}
	${DEVICE_TOKEN_FRAGMENT}
`;

const QUERY_SHADOW = gql`
	query _shadow($deviceid: String!) {
		shadow(deviceid: $deviceid) {
			...shadow
		}
	}
	${SHADOW_FRAGMENT}
`;

const CREATE_DEVICE = gql`
	mutation _createDevice($projectid: String!, $deviceid: String, $deviceinfo: DeviceInfo) {
		createDevice(projectid: $projectid, deviceid: $deviceid, deviceinfo: $deviceinfo) {
			...deviceWithToken
		}
	}
	${DEVICE_WITH_TOKEN_FRAGMENT}
`;

const UPDATE_DEVICE = gql`
	mutation _updateDevice($deviceid: String!, $deviceinfo: DeviceInfo) {
		updateDevice(deviceid: $deviceid, deviceinfo: $deviceinfo) {
			...deviceInfo
		}
	}
	${DEVICE_FRAGMENT}
`;

const DELETE_DEVICE = gql`
	mutation _deleteDevice($deviceid: String!) {
		deleteDevice(deviceid: $deviceid) {
			...deviceInfo
		}
	}
	${DEVICE_FRAGMENT}
`;

const GROUP_DEVICE = gql`
	mutation _groupDevice($deviceid: [String]!, $groupid: String!) {
		groupDevice(deviceid: $deviceid, groupid: $groupid) {
			...deviceInfo
		}
	}
	${DEVICE_FRAGMENT}
`;

const UNGROUP_DEVICE = gql`
	mutation _ungroupDevice($deviceid: [String]!) {
		ungroupDevice(deviceid: $deviceid) {
			...deviceInfo
		}
	}
	${DEVICE_FRAGMENT}
`;

const RESYNC_DEVICE_STATUS = gql`
	mutation _resyncDeviceStatus($deviceid: String!) {
		resyncDeviceStatus(deviceid: $deviceid) {
			...result
		}
	}
	${DEVICE_STATUS_FRAGMENT}
`;

const WRITE_SHADOW = gql`
	mutation _writeShadow($deviceid: String!, $data: JSON!, $timestamp: Timestamp, $mode: WriteMode) {
		writeShadow(deviceid: $deviceid, data: $data, timestamp: $timestamp, mode: $mode) {
			...shadow
		}
	}
	${SHADOW_FRAGMENT}
`;

const SUBSCRIPTION_SHADOW = gql`
	subscription _shadowUpdated($deviceid: String!) {
		shadowUpdated(deviceid: $deviceid) {
			...shadow
		}
	}
	${SHADOW_FRAGMENT}
`;

const UPDATE_SCHEMA = gql`
	mutation _updateSchema($deviceid: String!, $value: JSON) {
		updateSchema(deviceid: $deviceid, value: $value) {
			...schema
		}
	}
	${SCHEMA_FRAGMENT}
`;

const QUERY_TRIGGER = gql`
	query _trigger($deviceid: String!) {
		trigger(deviceid: $deviceid) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;

const PUBLISH_MSG_TO_DEVICE = gql`
	mutation _publishMessageToDevice($deviceid: String!, $topic: String!, $payload: String) {
		publishMessageToDevice(deviceid: $deviceid, topic: $topic, payload: $payload) {
			...result
		}
	}
	${DEVICE_STATUS_FRAGMENT}
`;

const SAVE_TRIGGER = gql`
	mutation _saveTrigger($deviceid: String!, $triggers: [TriggerInput]!) {
		saveTrigger(deviceid: $deviceid, triggers: $triggers) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;

const UPDATE_TRIGGER = gql`
	mutation _updateTrigger($deviceid: String!, $triggers: [TriggerInput]) {
		updateTrigger(deviceid: $deviceid, triggers: $triggers) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;

const ENABLE_TRIGGER = gql`
	mutation _enableTrigger($deviceid: String!, $triggerid: [String]!) {
		enableTrigger(deviceid: $deviceid, triggerid: $triggerid) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;

const DISABLE_TRIGGER = gql`
	mutation _disableTrigger($deviceid: String!, $triggerid: [String]!) {
		disableTrigger(deviceid: $deviceid, triggerid: $triggerid) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;

export {
	QUERY_DEVICE_LIST,
	QUERY_DEVICE_LIST_STATUS,
	QUERY_DEVICE,
	QUERY_DEVICE_WITH_DEVICE_TOKEN,
	QUERY_SHADOW,
	CREATE_DEVICE,
	UPDATE_DEVICE,
	DELETE_DEVICE,
	GROUP_DEVICE,
	UNGROUP_DEVICE,
	RESYNC_DEVICE_STATUS,
	WRITE_SHADOW,
	UPDATE_SCHEMA,
	QUERY_TRIGGER,
	SAVE_TRIGGER,
    ENABLE_TRIGGER,
    DISABLE_TRIGGER,
	UPDATE_TRIGGER,
	SUBSCRIPTION_SHADOW,
	PUBLISH_MSG_TO_DEVICE,
};
