const templateTrigger = 
  [
    {
      triggerid: "RELAY1ONOFF",
      type: "SHADOW",
      event: "UPDATED",
      action: "LINENOTIFY",
      condition: "$CUR.led0!=$PREV.led0",
      context: [
        {
          "key": "msg",
          "value": "{{$CUR.name}} สวิตซที่ 1 {{#compare $CUR.led0 \"==\" \"1\"}} เปิด {{else}} ปิด {{/compare}}"
        }
      ],
      enabled: true
    },
    {
      triggerid: "RELAY2ONOFF",
      type: "SHADOW",
      event: "UPDATED",
      action: "LINENOTIFY",
      condition: "$CUR.led1!=$PREV.led1",
      context: [
        {
          "key": "msg",
          "value": "{{$CUR.name}} สวิตซที่ 2 {{#compare $CUR.led1 \"==\" \"1\"}} เปิด {{else}} ปิด {{/compare}}"
        }
      ],
      enabled: true
    },
    {
      triggerid: "RELAY3ONOFF",
      type: "SHADOW",
      event: "UPDATED",
      action: "LINENOTIFY",
      condition: "$CUR.led2!=$PREV.led2",
      context: [
        {
          "key": "msg",
          "value": "{{$CUR.name}} สวิตซที่ 3 {{#compare $CUR.led2 \"==\" \"1\"}} เปิด {{else}} ปิด {{/compare}}"
        }
      ],
      enabled: true
    },
    {
      triggerid: "RELAY4ONOFF",
      type: "SHADOW",
      event: "UPDATED",
      action: "LINENOTIFY",
      condition: "$CUR.led3!=$PREV.led3",
      context: [
        {
          "key": "msg",
          "value": "{{$CUR.name}} สวิตซที่ 4 {{#compare $CUR.led3 \"==\" \"1\"}} เปิด {{else}} ปิด {{/compare}}"
        }
      ],
      enabled: true
    },
    {
      triggerid: "DEVICEONOFF",
      type: "DEVICE",
      event: "STATUSCHANGED",
      action: "LINENOTIFY",
      condition: "true",
      context: [
        {
          "key": "msg",
          "value": "{{$ALIAS}} {{$NEW.STATUSTEXT}}"
        }
      ],
      enabled: true
    }
]

export default templateTrigger;