export const parseMemberLevel = (level) => {
    switch (level) {
        case "GUEST":
            return 1;
            
        case "VIEWER":
            return 2;
            
        case "EDITOR":
            return 3;
            
        case "MASTER":
            return 4;
            
        case "OWNER":
            return 5;
        default:
            return level;
    }

}