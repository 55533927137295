import gql from 'graphql-tag';
import { PROJECT_FRAGMENT } from './project';

const BILLING_ACCOUNT_FRAGMENT = gql`
	fragment billingAccount on BillingAccount {
		billingaccountid
		billingaccountname
		balance
		quota
		limit
		available
		createdtime
		updatedtime
		mode
	}
`;

const QUERY_BILLING_ACCOUNT_DATA = gql`
	query _billingAccountData($billingaccountid: String) {
		billingAccount(billingaccountid: $billingaccountid) {
			...billingAccount
		}
		projectList {
			...project
		}
	}
	${BILLING_ACCOUNT_FRAGMENT}
	${PROJECT_FRAGMENT}
`;

// new ver

const MY_BILLING_ACCOUNT_FRAGMENT = gql`
	fragment myBillingAccount on BillingMembershipProfile {
		billingaccountid
		level
	}
`;

const BILLING_ACCOUNT_SUBSCRIBE_PROFILE_FRAGMENT = gql`
	fragment billingAccountSubscriptionProfile on BillingAccountSubscriptionProfile {
		billtype
		status
		packageid
		total_cycle
		current_cycle
		subscription_start_at
		current_cycle_start_at
		current_cycle_end_at
		packagename
	}
`;

const BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE_FRAGMENT = gql`
	fragment billingAccountResourceUsageProfile on BillingAccountResourceUsageProfile {
		billingaccountid
		max_dashboard_per_project
		max_dashboard_per_billing
		max_device_per_billing
		max_member_per_project
		max_project_per_billing
		use_dashboard
		use_device
		use_project
		detail
	}
`;

const BILLING_METRIC_USAGE_PROFILE_FRAGMENT = gql`
	fragment billingMetricUsageProfile on BillingMetricUsageProfile {
		billingaccountid
		max_apicall
		max_connection
		max_datasource
		max_message
		max_shadowops
		max_store
		max_trigger
		use_apicall
		use_connection
		use_datasource
		use_message
		use_shadowops
		use_store
		use_trigger
	}
`;

const QUERY_MY_BILLING_ACCOUNT = gql`
	query {
		MyBillingAccount {
			...myBillingAccount
		}
	}
	${MY_BILLING_ACCOUNT_FRAGMENT}
`;

const QUERY_USAGE_PAGE = gql`
	query ($billingaccountid: String!) {
		BillingAccountResourceUsageProfile(billingaccountid: $billingaccountid) {
			...billingAccountResourceUsageProfile
		}
		BillingMetricUsageProfile(billingaccountid: $billingaccountid) {
			...billingMetricUsageProfile
		}
		BillingAccountSubscriptionProfile(billingaccountid: $billingaccountid) {
			...billingAccountSubscriptionProfile
		}
		projectList {
			...project
		}
	}
	${BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE_FRAGMENT}
	${BILLING_METRIC_USAGE_PROFILE_FRAGMENT}
	${BILLING_ACCOUNT_SUBSCRIBE_PROFILE_FRAGMENT}
	${PROJECT_FRAGMENT}
`;

const QUERY_PROJECT_BILLING_USAGE = gql`
	query ($projectid: String!) {
		ProjectBillingMetricUsageProfile(projectid: $projectid) {
			...billingMetricUsageProfile
		}
		ProjectBillingAccountSubscriptionProfile(projectid: $projectid) {
			...billingAccountSubscriptionProfile
		}
	}
	${BILLING_METRIC_USAGE_PROFILE_FRAGMENT}
	${BILLING_ACCOUNT_SUBSCRIBE_PROFILE_FRAGMENT}
`;


export { QUERY_BILLING_ACCOUNT_DATA, QUERY_MY_BILLING_ACCOUNT, QUERY_USAGE_PAGE, QUERY_PROJECT_BILLING_USAGE };
