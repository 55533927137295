/* eslint-disable no-loop-func */
import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import { apolloQuery, apolloMutation } from 'utils/apollo';
import {
	QUERY_DEVICE_LIST,
	QUERY_DEVICE,
	CREATE_DEVICE,
	UPDATE_DEVICE,
	WRITE_SHADOW,
	DELETE_DEVICE,
	UPDATE_SCHEMA,
	UPDATE_TRIGGER,
	SAVE_TRIGGER,
	QUERY_TRIGGER,
	ENABLE_TRIGGER,
    DISABLE_TRIGGER,
	
} from 'utils/apollo/graphql/device';
import { QUERY_PROJECT } from 'utils/apollo/graphql/project';
import * as actions from 'redux/actions/deviceAction';
import schema from 'constants/schema.json';
// import trigger from 'constants/trigger.json';
import { push } from 'connected-react-router';
import templateTrigger from '../../constants/templateTrigger';

function* loadDevices(action) {
	try {
		const { filter } = action.payload;

		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: filter.projectid }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let i = 0;
		while (i < total) {
			const limit = i + 100;
			const { data, errors } = yield call(
				async () =>
					await apolloQuery(QUERY_DEVICE_LIST, {
						filter: { projectid: filter.projectid, tags: "{ A: handysense }" },
						limit: limit,
						offset: i
					})
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			i = limit;
		}

		

		yield put(actions.loadDevices.success(devices));
	} catch (error) {
		yield put(actions.loadDevices.failure(error));
	}
}

function* loadDevice(action) {
	try {
		const { deviceId } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_DEVICE, { deviceid: deviceId }));
		console.log(data);
		const TriggerRes = yield call(async () => await apolloQuery(QUERY_TRIGGER, { deviceid: deviceId }));
		if (TriggerRes.errors) throw TriggerRes.errors;

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadDevice.success({ ...data.device }));
			// yield put(actions.loadDevice.success({ ...data.device, trigger: TriggerRes.data.trigger.value }));
		}
	} catch (error) {
		console.log(error);
		yield put(actions.loadDevice.failure(error));
	}
}

function* createDevice(action) {
	try {
		const { device } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(CREATE_DEVICE, {
					projectid: device.projectid,
					deviceinfo: {
						alias: device.alias,
						description: device.description,
						tag: device.tag,
						tags: "{A:handysense}",
					},
				})
		);
		if (mutationRes.errors) throw mutationRes.errors;

		// write device name to shadow
		const writeShadowRes = yield call(
			async () =>
				await apolloMutation(WRITE_SHADOW, {
					deviceid: mutationRes.data.createDevice.deviceid,
					data: { name: device.alias },
				})
		);
		if (writeShadowRes.errors) throw writeShadowRes.errors;

		// write schema and trigger
		const writeSchemaRes = yield call(
			async () =>
				await apolloMutation(UPDATE_SCHEMA, {
					deviceid: mutationRes.data.createDevice.deviceid,
					value: JSON.stringify(schema),
				})
		);
		if (writeSchemaRes.errors) throw writeSchemaRes.errors;

		// const updateTriggerRes = yield call(
		// 	async () =>
		// 		await apolloMutation(SAVE_TRIGGER, {
		// 			deviceid: mutationRes.data.createDevice.deviceid,
		// 			type: trigger.type,
		// 			event: trigger.event,
		// 			condition: trigger.condition,
		// 			action: trigger.action,
		// 			context: trigger.context,
		// 			enabled: trigger.enabled					
		// 		})
		// );
		// if (updateTriggerRes.errors) throw updateTriggerRes.errors;
		
		//save trigger with template trigger in js file
		const updateTriggerRes = yield call(
			async () =>
				await apolloMutation(SAVE_TRIGGER, {
					deviceid: mutationRes.data.createDevice.deviceid,
					triggers: templateTrigger,
				})
		);
		if (updateTriggerRes.errors) throw updateTriggerRes.errors;
		// console.log(updateTriggerRes.data);

		//-----device list
		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: device.projectid }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let i = 0;
		while (i < total) {
			const limit = i + 100;
			const { data, errors } = yield call(
				async () =>
					await apolloQuery(QUERY_DEVICE_LIST, {
						filter: { projectid: device.projectid, tags: "{ A: handysense }" },
						limit,
						offset: i
					})
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			i = limit;
		}

		yield put(actions.createDevice.success(devices, mutationRes.data.createDevice));
		yield put({ type: 'CLEAR_MSG' });
	} catch (error) {
		yield put(actions.createDevice.failure(error));
	}
}

function* updateDevice(action) {
	try {
		const { projectId, deviceId, device } = action.payload;

		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_DEVICE, {
					deviceid: deviceId,
					deviceinfo: {
						alias: device.alias,
						description: device.description,
						tag: device.tag,
						tags: device.tags,
					},
				})
		);
		if (mutationRes.errors) throw mutationRes.errors;

		// update name to shadow
		const writeShadowRes = yield call(
			async () => await apolloMutation(WRITE_SHADOW, { deviceid: deviceId, data: { name: device.alias } })
		);
		if (writeShadowRes.errors) throw writeShadowRes.errors;

		//-----device list
		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let i = 0;
		while (i < total) {
			const limit = i + 100;
			const { data, errors } = yield call(
				async () =>
					await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: projectId }, limit, offset: i })
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			i = limit;
		}
		const oldGroupId = device.oldGroupId ? device.oldGroupId : null;
		const devicesIngroup = devices.filter((device) => device.groupid === oldGroupId);

		yield put(actions.updateDevice.success(devices, mutationRes.data.updateDevice, devicesIngroup));
	} catch (error) {
		yield put(actions.updateDevice.failure(error));
	}
}

function* deleteDevice(action) {
	try {
		const { projectId, deviceId, groupId } = action.payload;
		const mutationRes = yield call(
			async () => await apolloMutation(DELETE_DEVICE, { deviceid: deviceId, groupId })
		);

		//-----device list
		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let i = 0;
		while (i < total) {
			const limit = i + 100;
			const { data, errors } = yield call(
				async () =>
					await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: projectId }, offset: i, limit })
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			i = limit;
		}
		const devicesIngroup = devices.filter((device) => device.groupid === groupId);

		if (mutationRes.errors) {
			throw mutationRes.errors;
		} else {
			yield put(actions.deleteDevice.success(devices, mutationRes.data.deleteDevice, devicesIngroup));
			yield put(push(`/p/${projectId}`));
			yield put({ type: 'CLEAR_MSG' });
		}
	} catch (error) {
		yield put(actions.deleteDevice.failure(error));
	}
}

function* updateShadow(action) {
	try {
		const { deviceId, cmd, timestamp } = action.payload;

		const { data, errors } = yield call(async () => {
			if (!timestamp) {
				await apolloMutation(WRITE_SHADOW, {
					deviceid: deviceId,
					data: { ...cmd },
				});
			} else {
				await apolloMutation(WRITE_SHADOW, {
					deviceid: deviceId,
					data: { ...cmd },
					timestamp: timestamp,
				});
			}
		});
		if (errors) {
			throw errors;
		} else {
			yield put(actions.updateShadow.success(data.writeShadow));
		}
	} catch (error) {
		yield put(actions.updateShadow.failure(error));
	}
}

function* setNotifyRelay(action) {

	// let enabledlist = [];
	// let disabledlist = [];

	//console.log(action)
	try {
		const { deviceId, triggerid, value } = action.payload;

		// for relay 0 to 3 ...
		// for (let i=0; i<4; i++) {
		// 	if (cmd[`notify_relay${i}`] !== undefined) {
		// 		if (cmd[`notify_relay${i}`]) {
		// 			enabledlist.push(`RELAY${i}ON`);
		// 			enabledlist.push(`RELAY${i}OFF`);
		// 		}
		// 		else {
		// 			disabledlist.push(`RELAY${i}ON`);
		// 			disabledlist.push(`RELAY${i}OFF`);
		// 		}
		// 	}			
		// }

		if (value) {
			const { errors } = yield call(
				async () => await apolloMutation(ENABLE_TRIGGER, { deviceid: deviceId, triggerid: triggerid })
			)
			if (errors) {
				throw errors;
			}
		} else {
			const { errors } = yield call(
				async () => await apolloMutation(DISABLE_TRIGGER, { deviceid: deviceId, triggerid: triggerid })
			)
			
			if (errors) {
				throw errors;
			}
		}

		const TriggerRes = yield call(async () => await apolloQuery(QUERY_TRIGGER, { deviceid: deviceId }));

		if (TriggerRes.error) {
			throw TriggerRes.errors;
		} else {
			yield put(actions.setNotifyRelay.success(TriggerRes.data.trigger));
		}
		
	} catch (error) {
		yield put(actions.setNotifyRelay.failure(error));
	}
}

function* setNotifyStatus(action) {
	let errors;
	try {
		const { deviceId, enabled } = action.payload;
		if (action.payload.json) {
			const { errors } = yield call(
				async () => await apolloMutation(ENABLE_TRIGGER, { deviceid: deviceId, triggerid: 'DEVICENOTI' })
			)
			if (errors) {
				yield put(actions.setNotifyStatus.failure(errors));
				// throw errors;
			} else {
				yield put(actions.setNotifyStatus.success());
			}
		}
		else {
			const { errors } = yield call(
				async () => await apolloMutation(DISABLE_TRIGGER, { deviceid: deviceId, triggerid: 'DEVICENOTI' })
			)

			if (errors) {
				yield put(actions.setNotifyStatus.failure(errors));
				// throw errors;
			} else {
				yield put(actions.setNotifyStatus.success());
			}
		}
	} catch (error) {
		yield put(actions.setNotifyStatus.failure(error));
	}
  

  /*
	try {
		const { deviceId, enabled } = action.payload;
		const { errors } = yield call(
			async () => await apolloMutation(UPDATE_TRIGGER, { deviceid: deviceId, enabled: enabled })
		);

		if (errors) {
			throw errors;
		} else {
			yield put(actions.setNotifyStatus.success());
		}
	} catch (error) {
		yield put(actions.setNotifyStatus.failure(error));
	}
	*/
}

function* updateDevicesStatus(action) {
	try {
		const { devices, deviceid, status } = action.payload;

		const NEW_DEVICES = devices?.map((device) => (device.deviceid === deviceid ? { ...device, status } : device));
		yield put(actions.updateDevicesStatus.success(NEW_DEVICES));
	} catch (error) {
		yield put(actions.updateDevicesStatus.failure());
	}
}

function* loadTriggers(action) {
	try {
		const { deviceid } = action.payload;
		// const { data, errors } = yield call(async () => await apolloQuery(QUERY_DEVICE, { deviceid: deviceId }));
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_TRIGGER, { deviceid: deviceid }));
		// if (TriggerRes.errors) throw TriggerRes.errors;
		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadTriggers.success(data.trigger));
			// yield put(actions.loadTriggers.success({ ...data.device, trigger: TriggerRes.data.trigger.value }));
		}
	} catch (error) {
		yield put(actions.loadTriggers.failure(error));
	}
}

export default function* watchDevices() {
	yield all([
		takeLatest(actions.LOAD_DEVICES.REQUEST, loadDevices),
		takeLatest(actions.LOAD_DEVICE.REQUEST, loadDevice),
		takeEvery(actions.CREATE_DEVICE.REQUEST, createDevice),
		takeEvery(actions.UPDATE_DEVICE.REQUEST, updateDevice),
		takeEvery(actions.DELETE_DEVICE.REQUEST, deleteDevice),
		takeEvery(actions.UPDATE_SHADOW.REQUEST, updateShadow),
		takeEvery(actions.SET_NOTIFY_STATUS.REQUEST, setNotifyStatus),
		takeEvery(actions.SET_NOTIFY_RELAY.REQUEST, setNotifyRelay),
		takeLatest(actions.UPDATE_DEVICES_STATUS.REQUEST, updateDevicesStatus),
		takeLatest(actions.LOAD_TRIGGERS.REQUEST, loadTriggers),
	]);
}
