import { put, call, takeEvery, all, takeLatest } from 'redux-saga/effects';
import * as actions from 'redux/actions/projectAction';
import { apolloQuery, apolloMutation } from 'utils/apollo';
import {
	QUERY_PROJECT_LIST,
	QUERY_PROJECT,
	CREATE_PROJECT,
	UPDATE_PROJECT,
	DELETE_PROJECT,
} from 'utils/apollo/graphql/project';
import { QUERY_DEVICE_LIST_STATUS } from 'utils/apollo/graphql/device';
import { QUERY_MEMBERSHIP_LIST } from 'utils/apollo/graphql/membership';
import { push, replace } from 'connected-react-router';
import { CREATE_HOOK } from 'utils/apollo/graphql/hook';

function* loadProjects() {
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT_LIST));

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadProjects.success(data.projectList));
		}
	} catch (error) {
		yield put(actions.loadProjects.failure(error));
	}
}

function* loadProjectsWithMember(action) {
	try {
		const { userId } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT_LIST));
		const projects = [];
		yield* data.projectList.map(function* (project) {
			// get project membership
			const membershipQuery = yield call(
				async () => await apolloQuery(QUERY_MEMBERSHIP_LIST, { projectid: project.projectid })
			);

			if (membershipQuery.errors) throw membershipQuery.errors;

			const memberLevel = membershipQuery.data.membership.find((item) => item.userid === userId)?.level;

			// get devices status to subscription
			const deviceStatusQuery = yield call(
				async () =>
					await apolloQuery(QUERY_DEVICE_LIST_STATUS, {
						filter: { projectid: project.projectid, tags: "{A:handysense}" },
					})
			);

			if (deviceStatusQuery.errors) throw deviceStatusQuery.errors;
			let devices = [];
			const deviceStatusList = devices.concat(deviceStatusQuery.data.deviceList);
			projects.push({ ...project, level: memberLevel, devices: deviceStatusList });
		});

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadProjectsWithMember.success(projects));
		}
	} catch (error) {
		yield put(actions.loadProjectsWithMember.failure(error));
	}
}

function* loadProject(action) {
	try {
		const { projectId } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadProject.success(data.project));
		}
	} catch (error) {
		yield put(actions.loadProject.failure(error));
	}
}

function* createProject(action) {
	try {
		const { project } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(CREATE_PROJECT, {
					projectname: project.projectname,
					billingaccountid: project.billingaccountid,
					description: project.description,
					tag: project.tag,
					tags: "{A:handysense}",
				})
		);

		if (mutationRes.errors) throw mutationRes.errors;

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT_LIST));

		// create linenotify hook
		const createHookRes = yield call(
			async () =>
				await apolloMutation(CREATE_HOOK, {
					projectid: mutationRes.data.createProject.projectid,
					name: 'LINENOTIFY',
					description: null,
					type: 'WEBHOOK',
					enabled: true,
					param: JSON.stringify({
						uri: 'https://notify-api.line.me/api/notify',
						method: 'POST',
						header: {
							Authorization: 'Bearer {{option.linetoken}}',
							'Content-Type': 'application/x-www-form-urlencoded',
						},
						body: 'message={{context.msg}}',
					}),
				})
		);
		console.log('🚀 ~ file: projectSaga.js ~ line 124 ~ function*createProject ~ createHookRes', createHookRes);
		if (createHookRes.errors) throw createHookRes.errors;

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			yield put(
				actions.createProject.success(data.projectList, { ...mutationRes.data.createProject, devices: [] })
			);
			yield put(replace(`/p/${mutationRes.data.createProject.projectid}`));
		}
	} catch (error) {
		yield put(actions.createProject.failure(error));
	}
}

function* updateProject(action) {
	try {
		const { projectId, project } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_PROJECT, {
					projectid: projectId,
					projectname: project.projectname,
					description: project.description,
					tag: project.tag,
					tags: "{A:handysense}",
				})
		);

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT_LIST));

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			yield put(actions.updateProject.success(data.projectList, mutationRes.data.updateProject));
		}
	} catch (error) {
		yield put(actions.updateProject.failure(error));
	}
}

function* deleteProject(action) {
	try {
		const { projectId } = action.payload;
		const mutationRes = yield call(async () => await apolloMutation(DELETE_PROJECT, { projectid: projectId }));

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT_LIST));

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			if (mutationRes?.data?.deleteProject?.projectid === null) {
				yield put(actions.deleteProject.failure({ message: 'Something wrong' }));
			} else {
				yield put(actions.deleteProject.success(data.projectList));
				yield put(push('/'));
			}
		}
	} catch (error) {
		yield put(actions.deleteProject.failure(error));
	}
}

export default function* watchProjects() {
	yield all([
		takeLatest(actions.LOAD_PROJECTS.REQUEST, loadProjects),
		takeLatest(actions.LOAD_PROJECTS_WITH_MEMBER.REQUEST, loadProjectsWithMember),
		takeLatest(actions.LOAD_PROJECT.REQUEST, loadProject),
		takeEvery(actions.CREATE_PROJECT.REQUEST, createProject),
		takeEvery(actions.UPDATE_PROJECT.REQUEST, updateProject),
		takeEvery(actions.DELETE_PROJECT.REQUEST, deleteProject),
	]);
}
