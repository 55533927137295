export const SET_NOTE_OPEN = 'SET_NOTE_OPEN';
export const SET_LAYOUT_VISIBLE = 'SET_LAYOUT_VISIBLE';
export const SET_IS_FULLSCREEN = 'SET_IS_FULLSCREEN';

export const setNoteOpen = (isOpen) => ({
	type: SET_NOTE_OPEN,
	isOpen,
});

export const setLayoutVisible = (layoutVisible) => ({
	type: SET_LAYOUT_VISIBLE,
	layoutVisible,
});

export const setIsFullscreen = (isFullscreen) => ({
	type: SET_IS_FULLSCREEN,
	isFullscreen,
});
