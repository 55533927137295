import { gql } from '@apollo/client';

const HOOK_FRAGMENT = gql`
	fragment hook on Hook {
		projectid
		name
		hookid
		description
		type
		enabled
		param
	}
`;

const QUERY_HOOKS = gql`
	query _hooks($projectid: String!) {
		hook(projectid: $projectid) {
			...hook
		}
	}
	${HOOK_FRAGMENT}
`;

const CREATE_HOOK = gql`
	mutation _createHook($projectid: String!, $name: String!, $description: String, $type: String, $enabled: Boolean,$param: JSON) {
		createHook(
			projectid: $projectid
			name: $name
			description: $description
			type: $type
			enabled: $enabled
			param: $param
		) {
			...hook
		}
	}
	${HOOK_FRAGMENT}
`;

const UPDATE_HOOK = gql`
	mutation _updateHook(
		$projectid: String!
		$hookid: String!
		$name: String!
		$description: String
		$type: String
		$enabled: Boolean
		$param: JSON
	) {
		updateHook(
			projectid: $projectid
			hookid: $hookid
			name: $name
			description: $description
			type: $type
			enabled: $enabled
			param: $param
		) {
			...hook
		}
	}
	${HOOK_FRAGMENT}
`;

export { QUERY_HOOKS, CREATE_HOOK, UPDATE_HOOK };
