import { createTypes, createAction } from 'utils/createActionHelper';

const CLEAR_MSG = 'CLEAR_MSG';

const LOAD_DEVICES = createTypes('get', 'Devices');
const LOAD_DEVICE = createTypes('get', 'Device');
const CREATE_DEVICE = createTypes('create', 'Device');
const UPDATE_DEVICE = createTypes('update', 'Device');
const DELETE_DEVICE = createTypes('delete', 'Device');
const UPDATE_SHADOW = createTypes('update', 'Shadow');
const SET_NOTIFY_STATUS = createTypes('set', 'Notifystatus');
const SET_NOTIFY_RELAY = createTypes('set', 'Notifyrelay');
const UPDATE_DEVICES_STATUS = createTypes('update', 'Devicesstatus');
const LOAD_TRIGGERS = createTypes('get', 'Triggers');

const loadDevices = {
	request: (filter) => createAction(LOAD_DEVICES.REQUEST, { filter }),
	success: (devices) => createAction(LOAD_DEVICES.SUCCESS, { devices }),
	failure: (error) => createAction(LOAD_DEVICES.FAILURE, { error }),
};

const loadDevice = {
	request: (deviceId) => createAction(LOAD_DEVICE.REQUEST, { deviceId }),
	success: (device) => createAction(LOAD_DEVICE.SUCCESS, { device }),
	failure: (error) => createAction(LOAD_DEVICE.FAILURE, { error }),
};

const createDevice = {
	request: (device) => createAction(CREATE_DEVICE.REQUEST, { device }),
	success: (devices, device) => createAction(CREATE_DEVICE.SUCCESS, { devices, device }),
	failure: (error) => createAction(CREATE_DEVICE.FAILURE, { error }),
};

const updateDevice = {
	request: (projectId, deviceId, device) => createAction(UPDATE_DEVICE.REQUEST, { projectId, deviceId, device }),
	success: (devices, device, devicesInGroup) =>
		createAction(UPDATE_DEVICE.SUCCESS, { devices, device, devicesInGroup }),
	failure: (error) => createAction(UPDATE_DEVICE.FAILURE, { error }),
};

const deleteDevice = {
	request: (projectId, deviceId, groupId) => createAction(DELETE_DEVICE.REQUEST, { projectId, deviceId, groupId }),
	success: (devices, device, devicesInGroup) =>
		createAction(DELETE_DEVICE.SUCCESS, { devices, device, devicesInGroup }),
	failure: (error) => createAction(DELETE_DEVICE.FAILURE, { error }),
};

const updateShadow = {
	request: (deviceId, cmd, timestamp) => createAction(UPDATE_SHADOW.REQUEST, { deviceId, cmd, timestamp }),
	success: (shadow) => createAction(UPDATE_SHADOW.SUCCESS, { shadow }),
	failure: (error) => createAction(UPDATE_SHADOW.FAILURE, { error }),
};

const setNotifyStatus = {
	request: (deviceId, json) => createAction(SET_NOTIFY_STATUS.REQUEST, { deviceId, json }),
	success: () => createAction(SET_NOTIFY_STATUS.SUCCESS, {}),
	failure: (error) => createAction(SET_NOTIFY_STATUS.FAILURE, { error }),
};

const setNotifyRelay = {
	request: (deviceId, triggerid, value) => createAction(SET_NOTIFY_RELAY.REQUEST, { deviceId, triggerid, value }),
	success: (trigger) => createAction(SET_NOTIFY_RELAY.SUCCESS, { trigger }),
	failure: (error) => createAction(SET_NOTIFY_RELAY.FAILURE, { error }),
};


const clearMsg = () => ({ type: CLEAR_MSG });

const updateDevicesStatus = {
	request: (devices, deviceid, status) => createAction(UPDATE_DEVICES_STATUS.REQUEST, { devices, deviceid, status }),
	success: (devices) => createAction(UPDATE_DEVICES_STATUS.SUCCESS, { devices }),
	failure: () => createAction(UPDATE_DEVICES_STATUS.FAILURE, {}),
};

const loadTriggers = {
	request: (deviceid) => createAction(LOAD_TRIGGERS.REQUEST, { deviceid }),
	success: (triggers) => createAction(LOAD_TRIGGERS.SUCCESS, { triggers }),
	failure: (error) => createAction(LOAD_TRIGGERS.FAILURE, { error }),
};


export {
	LOAD_DEVICES,
	loadDevices,
	LOAD_DEVICE,
	loadDevice,
	CREATE_DEVICE,
	createDevice,
	UPDATE_DEVICE,
	updateDevice,
	CLEAR_MSG,
	clearMsg,
	DELETE_DEVICE,
	deleteDevice,
	UPDATE_SHADOW,
	updateShadow,
	SET_NOTIFY_STATUS,
	setNotifyStatus,
	SET_NOTIFY_RELAY,
	setNotifyRelay,
	UPDATE_DEVICES_STATUS,
	updateDevicesStatus,
	LOAD_TRIGGERS,
	loadTriggers,
};
