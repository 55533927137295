import { LOAD_BILLING_ACCOUNT,LOAD_DEVICE_HANDYSENSE, LOAD_PROJECT_BILLING_ACCOUNT } from 'redux/actions/billingAccountActions';
import { shapeMessage } from 'utils/openNotification';

const initialState = {
	billingAccountData: null,
	loading: false,
	message: null,
	projectBillingAccount: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_BILLING_ACCOUNT.REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOAD_BILLING_ACCOUNT.SUCCESS:
			return {
				...state,
				billingAccountData: action.payload.billingAccountData,
				message: shapeMessage(action.type),
				loading: false,
			};
		case LOAD_BILLING_ACCOUNT.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};
		case LOAD_PROJECT_BILLING_ACCOUNT.REQUEST:
			return {
				...state,
				loading: true,
				projectBillingAccount: null
			};
		case LOAD_PROJECT_BILLING_ACCOUNT.SUCCESS:
			return {
				...state,
				projectBillingAccount: action.payload.billingAccountData,
				message: shapeMessage(action.type),
				loading: false,
			};
		case LOAD_PROJECT_BILLING_ACCOUNT.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				projectBillingAccount: null,
				loading: false,
			};

		default:
			return state;
	}
};
