import {
	LOAD_PROJECTS,
	LOAD_PROJECTS_WITH_MEMBER,
	LOAD_PROJECT,
	CREATE_PROJECT,
	UPDATE_PROJECT,
	DELETE_PROJECT,
} from 'redux/actions/projectAction';
import { shapeMessage } from 'utils/openNotification';

const initState = {
	// projects
	projects: [],
	projectsLoading: true,
	projectsMessage: null,

	// project
	project: {
		projectname: '',
		createdtime: new Date(),
		projectid: '',
		description: '',
		tag: '',
		tags: null,
		numberdevice: 0,
		numberdeviceonline: 0,
		numberdeviceoffline: 0,
		numbergroup: 0,
		devices: [],
	},
	loading: false,
	message: null,
	projectCUDLoading: false,
};

export default (state = initState, action) => {
	switch (action.type) {
		// ----- projects -----
		case LOAD_PROJECTS.REQUEST:
			return {
				...state,
				projectsMessage: null,
				projectsLoading: true,
			};
		case LOAD_PROJECTS.SUCCESS:
			return {
				...state,
				projects: action.payload.projects,
				projectsMessage: null,
				projectsLoading: false,
			};
		case LOAD_PROJECTS.FAILURE:
			return {
				...state,
				projectsMessage: shapeMessage(action.type, action.payload.error),
				projectsLoading: false,
			};

		case LOAD_PROJECTS_WITH_MEMBER.REQUEST:
			return {
				...state,
				projectsMessage: null,
				projectsLoading: true,
			};
		case LOAD_PROJECTS_WITH_MEMBER.SUCCESS:
			return {
				...state,
				projects: action.payload.projects,
				projectsMessage: null,
				projectsLoading: false,
			};
		case LOAD_PROJECTS_WITH_MEMBER.FAILURE:
			return {
				...state,
				projectsMessage: shapeMessage(action.type, action.payload.error),
				projectsLoading: false,
			};
		/*------------------------------------------------------------*/
		case CREATE_PROJECT.REQUEST:
			return {
				...state,
				message: null,
				projectCUDLoading: true,
			};
		case CREATE_PROJECT.SUCCESS:
			return {
				...state,
				projects: action.payload.projects,
				project: action.payload.project,
				message: shapeMessage(action.type),
				projectCUDLoading: false,
			};
		case CREATE_PROJECT.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				projectCUDLoading: false,
			};

		// ----- project -----
		case LOAD_PROJECT.REQUEST:
			return {
				...state,
				message: null,
				loading: true,
			};
		case LOAD_PROJECT.SUCCESS:
			return {
				...state,
				project: action.payload.project,
				message: null,
				loading: false,
			};
		case LOAD_PROJECT.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};

		/*------------------------------------------------------------*/
		case UPDATE_PROJECT.REQUEST:
			return {
				...state,
				message: null,
				projectCUDLoading: true,
			};
		case UPDATE_PROJECT.SUCCESS:
			return {
				...state,
				projects: action.payload.projects,
				project: action.payload.project,
				message: shapeMessage(action.type),
				projectCUDLoading: false,
			};
		case UPDATE_PROJECT.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				projectCUDLoading: false,
			};
		/*------------------------------------------------------------*/
		case DELETE_PROJECT.REQUEST:
			return {
				...state,
				message: null,
				projectCUDLoading: true,
			};
		case DELETE_PROJECT.SUCCESS:
			return {
				...state,
				projects: action.payload.projects,
				message: shapeMessage(action.type),
				projectCUDLoading: false,
			};
		case DELETE_PROJECT.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				projectCUDLoading: false,
			};
		default:
			return state;
	}
};
