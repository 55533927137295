import { createTypes, createAction } from 'utils/createActionHelper';

const LOAD_FEED = createTypes('get', 'Feed');
const LOAD_LOGGING = createTypes('get', 'Logging');
const CRUD_LOGGING = createTypes('crud', 'Logging');

const loadFeed = {
	request: (startTime, endTime, deviceid, downsampling_unit, attr) =>
		createAction(LOAD_FEED.REQUEST, {
			startTime,
			endTime,
			deviceid,
			downsampling_unit,
			attr,
		}),
	success: (feed) => createAction(LOAD_FEED.SUCCESS, { feed }),
	failure: () => createAction(LOAD_FEED.FAILURE),
};

const loadLogging = {
	request: (projectid, deviceid) => createAction(LOAD_LOGGING.REQUEST, { projectid, deviceid }),
	success: (feed) => createAction(LOAD_LOGGING.SUCCESS, { feed }),
	failure: () => createAction(LOAD_LOGGING.FAILURE),
};

const crudLogging = {
	request: (deviceid, log, type) => createAction(CRUD_LOGGING.REQUEST, { deviceid, log, type }),
	success: (feed) => createAction(CRUD_LOGGING.SUCCESS, { feed }),
	failure: () => createAction(CRUD_LOGGING.FAILURE),
};

export { LOAD_FEED, loadFeed, LOAD_LOGGING, loadLogging, CRUD_LOGGING, crudLogging };
