import { createTypes, createAction } from 'utils/createActionHelper';

const LOAD_MEMBERSHIPS = createTypes('get', 'Memberships');
const CREATE_MEMBERSHIP = createTypes('create', 'Membership');
const UPDATE_MEMBERSHIP = createTypes('update', 'Membership');
const DELETE_MEMBERSHIP = createTypes('delete', 'Membership');
const LEAVE_PROJECT = createTypes('leave', 'Project');

const loadMemberships = {
	request: (projectId) => createAction(LOAD_MEMBERSHIPS.REQUEST, { projectId }),
	success: (memberships) => createAction(LOAD_MEMBERSHIPS.SUCCESS, { memberships }),
	failure: (error) => createAction(LOAD_MEMBERSHIPS.FAILURE, { error }),
};

const createMembership = {
	request: (membership) => createAction(CREATE_MEMBERSHIP.REQUEST, { membership }),
	success: (memberships) => createAction(CREATE_MEMBERSHIP.SUCCESS, { memberships }),
	failure: (error) => createAction(CREATE_MEMBERSHIP.FAILURE, { error }),
};

const updateMembership = {
	request: (membership) => createAction(UPDATE_MEMBERSHIP.REQUEST, { membership }),
	success: (memberships) => createAction(UPDATE_MEMBERSHIP.SUCCESS, { memberships }),
	failure: (error) => createAction(UPDATE_MEMBERSHIP.FAILURE, { error }),
};

const deleteMembership = {
	request: (projectId, username) => createAction(DELETE_MEMBERSHIP.REQUEST, { projectId, username }),
	success: (project) => createAction(DELETE_MEMBERSHIP.SUCCESS, { project }),
	failure: (error) => createAction(DELETE_MEMBERSHIP.FAILURE, { error }),
};

const leaveProject = {
	request: (projectId) => createAction(LEAVE_PROJECT.REQUEST, { projectId }),
	success: (membership) => createAction(LEAVE_PROJECT.SUCCESS, { membership }),
	failure: (error) => createAction(LEAVE_PROJECT.FAILURE, { error }),
};

export {
	LOAD_MEMBERSHIPS,
	loadMemberships,
	CREATE_MEMBERSHIP,
	createMembership,
	UPDATE_MEMBERSHIP,
	updateMembership,
	DELETE_MEMBERSHIP,
	deleteMembership,
	LEAVE_PROJECT,
	leaveProject,
};
