import { all, put, call, takeLatest, takeEvery, select, delay } from 'redux-saga/effects';
import axios from 'axios';
import * as actions from 'redux/actions/feedAction';
import cookie from 'utils/cookie';
import { startOfDay, startOfMonth, endOfDay, getTime } from 'date-fns';
import { apolloMutation, apolloQuery } from 'utils/apollo';
import { QUERY_MEMBERSHIP_LIST } from 'utils/apollo/graphql/membership';
import { QUERY_USER } from 'utils/apollo/graphql/user';
import { WRITE_SHADOW } from 'utils/apollo/graphql/device';
import dayjs from 'dayjs';

function* loadFeed(action) {
	try {
		const { startTime, endTime, deviceid, downsampling_unit, attr } = action.payload;
		const START = dayjs(startTime._d).valueOf();
		const END = dayjs(endTime._d).valueOf();

		const body = {
			start_absolute: START,
			end_absolute: END,
			metrics: [
				{
					name: deviceid,
					group_by: [
						{
							name: 'tag',
							tags: ['attr'],
						},
					],
					tags: {
						attr: attr,
					},
					aggregators: [
						{
							name: 'avg',
							sampling: {
								value: 1,
								unit: downsampling_unit,
							},
						},
					],
				},
			],
		};

		const access_token = cookie.get('access_token');
		const res = yield call(axios.post, process.env.REACT_APP_FEED_URL, body, {
			headers: {
				Authorization: `Bearer ${access_token}`,
			},
		});

		yield put(
			actions.loadFeed.success({
				data:
					res.data.queries[0].results.length !== 0 && res.data.queries[0].results[0].tags.attr
						? res.data.queries[0].results.map((item) => ({
								name: item.tags.attr,
								data: item.values,
						  }))
						: [
								{ name: ['temperature'], data: [] },
								{ name: ['soil'], data: [] },
								{ name: ['lux'], data: [] },
								{ name: ['humidity'], data: [] },
						  ],
			})
		);
	} catch (error) {
		yield put(actions.loadFeed.failure(error));
	}
}

function* loadLogging(action) {
	try {
		const { projectid, deviceid } = action.payload;

		// get log
		const body = {
			start_absolute: getTime(startOfDay(startOfMonth(new Date()))),
			end_absolute: getTime(endOfDay(new Date())),
			metrics: [
				{
					name: deviceid,
					group_by: [
						{
							name: 'tag',
							tags: ['attr'],
						},
					],
					tags: {
						attr: ['log'],
					},
					aggregators: [
						{
							name: 'avg',
							sampling: {
								value: 1,
								unit: 'seconds',
							},
						},
					],
				},
			],
		};

		const access_token = cookie.get('access_token');
		const res = yield call(axios.post, process.env.REACT_APP_FEED_URL, body, {
			headers: {
				Authorization: `Bearer ${access_token}`,
			},
		});

		// get member
		const membershipQuery = yield call(async () => await apolloQuery(QUERY_MEMBERSHIP_LIST, { projectid }));
		const members = [];
		yield* membershipQuery.data.membership.map(function* (member) {
			// get user by user id
			const userQuery = yield call(async () => await apolloQuery(QUERY_USER, { userid: member.userid }));
			if (userQuery.errors) throw userQuery.errors;
			const fullname = userQuery?.data?.userByUserID?.profile?.fullname;

			members.push({ ...member, fullname });
		});

		if (membershipQuery.errors) throw membershipQuery.errors;

		// formatting data
		const DATA = res?.data?.queries[0]?.results[0]?.values;
		const list = [];
		DATA.forEach((element) => {
			const msgnote = element[1].split('#handytext@');
			if (msgnote.length === 3) {
				const note = {
					topic: msgnote[0],
					description: msgnote[1],
					createdtime: element[0],
					author: members?.find((el) => el.username === msgnote[2])?.fullname || '',
					email: msgnote[2],
				};
				list.unshift(note);
			}
		});

		yield put(actions.loadLogging.success(list));
	} catch (error) {
		yield put(actions.loadLogging.failure(error));
	}
}

function* crudLogging(action) {
	try {
		const { deviceid, log, type } = action.payload;

		const msg =
			type === 'delete'
				? `delete#handytext@${log?.email}`
				: `${log?.topic}#handytext@${log?.description || ''}#handytext@${log?.email}`;

		yield call(async () => {
			if (log.createdtime === null || log.createdtime === undefined) {
				return await apolloMutation(WRITE_SHADOW, {
					deviceid: deviceid,
					data: { log: msg },
				});
			} else {
				return await apolloMutation(WRITE_SHADOW, {
					deviceid: deviceid,
					data: { log: msg },
					timestamp: log.createdtime,
				});
			}
		});

		let prevList = yield select(({ feedReducer }) => feedReducer.metricsLogging);
		if (type === 'create') {
			prevList.unshift(log);
		}

		const newlist =
			type === 'create'
				? prevList
				: type === 'update'
				? prevList.map((note) => (note.createdtime === log.createdtime ? { ...log } : note))
				: prevList.filter((note) => note.createdtime !== log.createdtime);

		console.log(newlist);

		yield put(actions.crudLogging.success(newlist));
	} catch (error) {
		yield put(actions.crudLogging.failure(error));
	}
}

export default function* watchFeed() {
	yield all([
		takeLatest(actions.LOAD_FEED.REQUEST, loadFeed),
		takeLatest(actions.LOAD_LOGGING.REQUEST, loadLogging),
		takeLatest(actions.CRUD_LOGGING.REQUEST, crudLogging),
	]);
}
