import { all, put, call, fork, select, take, delay, takeLeading } from 'redux-saga/effects';
import * as actions from 'redux/actions/userActions';
import { apolloQuery, apolloMutation } from 'utils/apollo';
import { QUERY_USER, UPDATE_USER } from 'utils/apollo/graphql/user';
import cookies from 'utils/cookie';
import { decodeToken } from 'jsontokens';
import axios from 'axios';
import dayjs from 'dayjs';
import { signOut, AUTHENTICATED } from 'redux/actions/authAction';
import { push } from 'connected-react-router';

function* takeOneAndBlock(pattern, worker, ...args) {
	const task = yield fork(function* () {
		while (true) {
			const action = yield take(pattern);
			yield call(worker, ...args, action);
		}
	});
	return task;
}

function* loadUser(action) {
	try {
		const { access_token, expires } = action.payload;
		const res = decodeToken(access_token);
		const user_id = res?.payload?.ctx?.userid || res?.payload?.ctx?.owner;

		if (expires) {
			yield call(() =>
				cookies.set('user_id', user_id, { path: '/', expires: new Date(dayjs.unix(Number(expires))) })
			);
		}
		if (access_token) {
			yield call(() => cookies.set('access_token', access_token, { path: '/' }));
		}
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_USER, { userid: user_id }));

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadUser.success(data.userByUserID));
			yield put({ type: AUTHENTICATED });
			yield delay(300);
			const prevLocation = yield select(({ router }) => router.location);
			const routePath = prevLocation.hash ? '/' : prevLocation.pathname ? prevLocation.pathname : '/';
			yield put(push(routePath));
		}
	} catch (error) {
		yield put(actions.loadUser.failure(error));
		yield delay(300);
		if (error?.[0]?.message === 'Invalid access token') {
			yield put(signOut());
		}
	}
}

function* refreshUser(action) {
	try {
		const { old_access_token } = action.payload;

		const refreshResponse = yield call(
			async () =>
				await axios.post(`${process.env.REACT_APP_AUTH_URL}/refreshtokenByAccesstoken`, {
					access_token: old_access_token,
				})
		);

		if (!refreshResponse.data) throw new Error('Auto Refresh token failed, Please try again manually.');

		const new_access_token = refreshResponse.data.accesstoken;
		const new_expires = refreshResponse.data.exp;
		const res = decodeToken(new_access_token);
		const user_id = res?.payload?.ctx?.owner;

		yield call(() => cookies.set('access_token', new_access_token, { path: '/' }));
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_USER, { userid: user_id }));

		if (errors) {
			throw errors;
		} else {
			yield call(() =>
				cookies.set('user_id', user_id, { path: '/', expires: new Date(dayjs.unix(Number(new_expires))) })
			);

			yield put(actions.refreshUser.success(data.userByUserID));
			yield put({ type: AUTHENTICATED });
			yield delay(300);
			const prevLocation = yield select(({ router }) => router.location);
			const routePath = prevLocation.hash ? '/' : prevLocation.pathname ? prevLocation.pathname : '/';
			yield put(push(routePath));
		}
	} catch (error) {
		yield put(actions.refreshUser.failure(error));
		yield put(signOut());
		// yield delay(300);
		// if (error?.[0]?.message === 'Invalid access token') {
		// 	yield call(() => signOut());
		// }
	}
}

function* updateUser(action) {
	try {
		const { updateData } = action.payload;
		const { data, errors } = yield call(async () => await apolloMutation(UPDATE_USER, updateData));
		if (errors) {
			throw errors;
		} else {
			yield put(actions.updateUser.success(data.updateUserProfile));
		}
	} catch (error) {
		yield put(actions.updateUser.failure(error));
	}
}

export default function* watchUsers() {
	yield all([
		takeOneAndBlock(actions.LOAD_USER.REQUEST, loadUser),
		takeOneAndBlock(actions.REFRESH_USER.REQUEST, refreshUser),
		takeLeading(actions.UPDATE_USER.REQUEST, updateUser),
	]);
}
