import { createTypes, createAction } from 'utils/createActionHelper';

const LOAD_HOOKS = createTypes('get', 'Hooks');
const UPDATE_HOOK = createTypes('update', 'Hook');

const loadHooks = {
	request: projectId => createAction(LOAD_HOOKS.REQUEST, { projectId }),
	success: hooks => createAction(LOAD_HOOKS.SUCCESS, { hooks }),
	failure: error => createAction(LOAD_HOOKS.FAILURE, { error }),
};

const updateHook = {
	request: (projectId, hookId, hook) => createAction(UPDATE_HOOK.REQUEST, { projectId, hookId, hook }),
	success: (hooks, hook) => createAction(UPDATE_HOOK.SUCCESS, { hooks, hook }),
	failure: error => createAction(UPDATE_HOOK.FAILURE, { error }),
};

export {
	LOAD_HOOKS,
	loadHooks,
	UPDATE_HOOK,
	updateHook,
};
