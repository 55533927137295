import { createTypes, createAction } from 'utils/createActionHelper';

const LOAD_USER = createTypes('get', 'User');
const REFRESH_USER = createTypes('refresh', 'User');
const UPDATE_USER = createTypes('update', 'User');

const loadUser = {
	request: (access_token, expires) => createAction(LOAD_USER.REQUEST, { access_token, expires }),
	success: (user) => createAction(LOAD_USER.SUCCESS, { user }),
	failure: (error) => createAction(LOAD_USER.FAILURE, { error }),
};

const refreshUser = {
	request: (old_access_token) => createAction(REFRESH_USER.REQUEST, { old_access_token }),
	success: (user) => createAction(REFRESH_USER.SUCCESS, { user }),
	failure: (error) => createAction(REFRESH_USER.FAILURE, { error }),
};

const updateUser = {
	request: (updateData) => createAction(UPDATE_USER.REQUEST, { updateData }),
	success: (user) => createAction(UPDATE_USER.SUCCESS, { user }),
	failure: (error) => createAction(UPDATE_USER.FAILURE, { error }),
};

export { LOAD_USER, loadUser, REFRESH_USER, refreshUser, UPDATE_USER, updateUser };
