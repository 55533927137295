import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as actions from 'redux/actions/hookAction';
import { apolloQuery, apolloMutation } from 'utils/apollo';
import { QUERY_HOOKS, UPDATE_HOOK } from 'utils/apollo/graphql/hook';

function* loadHooks(action) {
	try {
		const { projectId } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_HOOKS, { projectid: projectId }));

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadHooks.success(data.hook));
		}
	} catch (error) {
		yield put(actions.loadHooks.failure(error));
	}
}

function* updateHook(action) {
	try {
		const { projectId, hookId, hook } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_HOOK, {
					projectid: projectId,
					hookid: hookId,
					name: hook.name,
					description: hook.description,
					type: hook.type,
					enabled: hook.enabled,
					param: hook.param,
				})
		);

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_HOOKS, { projectid: projectId }));

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			yield put(actions.updateHook.success(data.hook, mutationRes.data.updateHook));
		}
	} catch (error) {
		console.log(error);
		yield put(actions.updateHook.failure(error));
	}
}

export default function* watchHook() {
	yield all([
		takeLatest(actions.LOAD_HOOKS.REQUEST, loadHooks),
		takeLatest(actions.UPDATE_HOOK.REQUEST, updateHook),
	]);
}
