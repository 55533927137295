import { gql } from '@apollo/client';

const PROJECT_FRAGMENT = gql`
	fragment project on Project {
		projectname
		projectid
		createdtime
		description
		tags
		tag
		numberdevice
		numbergroup
		numberdeviceonline
		numberdeviceoffline
		quota {
			apicall
			connection
			message
			shadowops
			store
			trigger
			datasource
		}
	}
`;

const QUERY_PROJECT_LIST = gql`
	query _projectList {
		projectList(limit: 100, filter: { tags: "{A:handysense}" }, orderBy: createdtime, direction: DESC) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const QUERY_PROJECT = gql`
	query _project($projectid: String!) {
		project(projectid: $projectid) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const CREATE_PROJECT = gql`
	mutation _createProject($projectname: String!, $billingaccountid: String, $description: String) {
		createProject(
			projectname: $projectname
			billingaccountid: $billingaccountid
			description: $description
			tags: "{A:handysense}"
		) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const UPDATE_PROJECT = gql`
	mutation _updateProject($projectid: String!, $projectname: String, $description: String, $tag: String) {
		updateProject(projectid: $projectid, projectname: $projectname, description: $description, tag: $tag) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const DELETE_PROJECT = gql`
	mutation _deleteProject($projectid: String!) {
		deleteProject(projectid: $projectid) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

export { PROJECT_FRAGMENT, QUERY_PROJECT_LIST, QUERY_PROJECT, CREATE_PROJECT, UPDATE_PROJECT, DELETE_PROJECT };
