import { CRUD_LOGGING, LOAD_FEED, LOAD_LOGGING } from 'redux/actions/feedAction';
import { shapeMessage } from 'utils/openNotification';

const initialState = {
	loading: false,
	loadingLogging: false,
	metrics: [],
	metricsLogging: [],
	message: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_FEED.REQUEST:
			return {
				...state,
				loading: true,
				message: null,
			};

		case LOAD_FEED.SUCCESS:
			return {
				...state,
				metrics: action.payload.feed.data,
				loading: false,
				message: null,
			};
		case LOAD_FEED.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		////////////////////////  Logging ////////////////////////////

		case LOAD_LOGGING.REQUEST:
			return {
				...state,
				loadingLogging: true,
				message: null,
			};

		case LOAD_LOGGING.SUCCESS:
			return {
				...state,
				metricsLogging: action.payload.feed,
				loadingLogging: false,
				message: null,
			};

		case LOAD_LOGGING.FAILURE:
			return {
				...state,
				loadingLogging: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		case CRUD_LOGGING.REQUEST:
			return {
				...state,
				loadingLogging: true,
			};
		case CRUD_LOGGING.SUCCESS:
			// console.log('CRUD SUCCESS', action.payload);
			return {
				...state,
				metricsLogging: action.payload.feed,
				loadingLogging: false,
			};
		case CRUD_LOGGING.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loadingLogging: false,
			};

		default:
			return state;
	}
};
