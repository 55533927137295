import { notification } from 'antd';
import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';
import TH from 'i18n/th.json';

const getTHWord = (en) => {
	return TH[lowerCase(en)] || en;
};

const shapeMessage = (actionType, description) => {
	const tempType = actionType.split('_');
	const type = tempType[tempType.length - 1] === 'SUCCESS' ? 'success' : 'error';
	const message = `${tempType.map((word) => getTHWord(word))}`.replaceAll(',', '');
	const DESCRIPTION = Array.isArray(description) ? description[0]?.message : description?.message;

	return {
		type,
		message,
		description: upperFirst(lowerCase(DESCRIPTION)),
	};
};

const openNotification = (msg) => {
	try {
		const { type, message, description } = msg;

		switch (type) {
			case 'success':
				notification[type]({
					message,
					description,
					duration: 3,
				});
				break;
			case 'error':
				notification[type]({
					message: message || 'เกิดข้อผิดพลาด',
					description: description || 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง',
					duration: 3,
				});
				break;
			default:
				break;
		}
	} catch (e) {
		return null;
	}
};

export { shapeMessage, openNotification };
