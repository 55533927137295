import {
	LOAD_DEVICES,
	LOAD_DEVICE,
	CREATE_DEVICE,
	UPDATE_DEVICE,
	DELETE_DEVICE,
	CLEAR_MSG,
	UPDATE_SHADOW,
	SET_NOTIFY_STATUS,
	SET_NOTIFY_RELAY,
	UPDATE_DEVICES_STATUS,
	LOAD_TRIGGERS,
} from 'redux/actions/deviceAction';
import { shapeMessage } from 'utils/openNotification';

const initState = {
	devices: [],
	devicesLoading: false,
	devicesMessage: null,
	deviceCUDLoading: false, //CUD stand for Create Update Delete

	device: null,
	loading: false,
	message: null,

	sendingMsg: false,
	updatingShadow: false,
	updatingNotifyRelay: false,
	setNotiStatusLoading: false,
	shadow: {},

	triggers: [],
	messageNotify: null
};

export default (state = initState, action) => {
	switch (action.type) {
		case LOAD_DEVICES.REQUEST:
			return {
				...state,
				devicesLoading: true,
				devicesMessage: null,
			};
		case LOAD_DEVICES.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				devicesLoading: false,
				devicesMessage: null,
			};
		case LOAD_DEVICES.FAILURE:
			return {
				...state,
				devicesLoading: false,
				devicesMessage: shapeMessage(action.type, action.payload.error),
			};
		/*------------------------------------------------------------*/
		case LOAD_DEVICE.REQUEST:
			return {
				...state,
				message: null,
				loading: true,
			};
		case LOAD_DEVICE.SUCCESS:
			return {
				...state,
				device: action.payload.device,
				message: null,
				loading: false,
			};
		case LOAD_DEVICE.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};
		/*------------------------------------------------------------*/
		case CREATE_DEVICE.REQUEST:
			return {
				...state,
				message: null,
				deviceCUDLoading: true,
			};
		case CREATE_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				device: action.payload.device,
				message: { ...shapeMessage(action.type), flag: action.type },
				deviceCUDLoading: false,
			};
		case CREATE_DEVICE.FAILURE:
			return {
				...state,
				message: { ...shapeMessage(action.type, action.payload.error), flag: action.type },
				deviceCUDLoading: false,
			};
		/*------------------------------------------------------------*/
		case UPDATE_DEVICE.REQUEST:
			return {
				...state,
				message: null,
				deviceCUDLoading: true,
			};
		case UPDATE_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				devicesInGroup: action.payload.devicesInGroup,
				device: action.payload.device,
				message: shapeMessage(action.type),
				deviceCUDLoading: false,
			};
		case UPDATE_DEVICE.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				deviceCUDLoading: false,
			};

		case DELETE_DEVICE.REQUEST:
			return {
				...state,
				message: null,
				deviceCUDLoading: true,
			};
		case DELETE_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				device: action.payload.device,
				devicesInGroup: action.payload.devicesInGroup,
				message: { ...shapeMessage(action.type), flag: action.type },
				deviceCUDLoading: false,
			};
		case DELETE_DEVICE.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				deviceCUDLoading: false,
			};

		///////////////////////   UPDATE SHADOW  ///////////////////////////

		case UPDATE_SHADOW.REQUEST:
			return {
				...state,
				updatingShadow: true,
			};
		case UPDATE_SHADOW.SUCCESS:
			return {
				...state,
				shadow: action.payload.shadow,
				updatingShadow: false,
			};
		case UPDATE_SHADOW.FAILURE:
			return {
				...state,
				updatingShadow: false,
			};

		///////////////////////   NOTIFY RELAY ///////////////////////////

		case SET_NOTIFY_RELAY.REQUEST:
			return {
				...state,
				updatingNotifyRelay: true,
				messageNotify: null
			};
		case SET_NOTIFY_RELAY.SUCCESS:
			return {
				...state,
				updatingNotifyRelay: false,
				triggers: action.payload.trigger,
				messageNotify: 'success'
			};
		case SET_NOTIFY_RELAY.FAILURE:
			return {
				...state,
				updatingNotifyRelay: false,
				messageNotify: 'error'
			};

		///////////////////////     ///////////////////////////

		case SET_NOTIFY_STATUS.REQUEST:
			return {
				...state,
				setNotiStatusLoading: true,
			};
		case SET_NOTIFY_STATUS.SUCCESS:
			return {
				...state,
				setNotiStatusLoading: false,
			};
		case SET_NOTIFY_STATUS.FAILURE:
			return {
				...state,
				setNotiStatusLoading: false,
			};

		case CLEAR_MSG:
			return {
				...state,
				message: null,
			};

		case UPDATE_DEVICES_STATUS.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
			};
		case LOAD_TRIGGERS.REQUEST:
			return {
				...state,
				devicesLoading: true,
				devicesMessage: null,
			};
		case LOAD_TRIGGERS.SUCCESS:
			return {
				...state,
				triggers: action.payload.triggers,
				devicesLoading: false,
				devicesMessage: null,
			};
		case LOAD_TRIGGERS.FAILURE:
			return {
				...state,
				devicesLoading: false,
				devicesMessage: shapeMessage(action.type, action.payload.error),
			};

		default:
			return state;
	}
};
