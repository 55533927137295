import { LOAD_USER, REFRESH_USER, UPDATE_USER } from 'redux/actions/userActions';
import { shapeMessage } from 'utils/openNotification';

const initialState = {
	user: null,
	loading: false,
	message: null,
	refreshLoading: false,
	refreshMessage: null,

	userCUDloading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_USER.REQUEST:
			return {
				...state,
				loading: true,
				message: null,
			};
		case LOAD_USER.SUCCESS:
			return {
				...state,
				user: action.payload.user,
				loading: false,
				message: null,
			};
		case LOAD_USER.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		case REFRESH_USER.REQUEST:
			return {
				...state,
				refreshLoading: true,
				refreshMessage: null,
			};
		case REFRESH_USER.SUCCESS:
			return {
				...state,
				user: action.payload.user,
				refreshloading: false,
				refreshMessage: shapeMessage(action.type),
			};
		case REFRESH_USER.FAILURE:
			return {
				...state,
				refreshloading: false,
				refreshMessage: shapeMessage(action.type, action.payload.error),
			};

		case UPDATE_USER.REQUEST:
			return {
				...state,
				userCUDloading: true,
				message: null,
			};
		case UPDATE_USER.SUCCESS:
			return {
				...state,
				user: action.payload.user,
				userCUDloading: false,
				message: shapeMessage(action.type),
			};
		case UPDATE_USER.FAILURE:
			return {
				...state,
				userCUDloading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		default:
			return state;
	}
};
