import { createTypes, createAction } from 'utils/createActionHelper';

const LOAD_PROJECTS = createTypes('get', 'Projects');
const LOAD_PROJECTS_WITH_MEMBER = createTypes('get', 'ProjectsWithMember');
const LOAD_PROJECT = createTypes('get', 'Project');
const CREATE_PROJECT = createTypes('create', 'Project');
const UPDATE_PROJECT = createTypes('update', 'Project');
const DELETE_PROJECT = createTypes('delete', 'Project');

const loadProjects = {
	request: () => createAction(LOAD_PROJECTS.REQUEST),
	success: (projects) => createAction(LOAD_PROJECTS.SUCCESS, { projects }),
	failure: (error) => createAction(LOAD_PROJECTS.FAILURE, { error }),
};

const loadProjectsWithMember = {
	request: (userId) => createAction(LOAD_PROJECTS_WITH_MEMBER.REQUEST, { userId }),
	success: (projects) => createAction(LOAD_PROJECTS_WITH_MEMBER.SUCCESS, { projects }),
	failure: (error) => createAction(LOAD_PROJECTS_WITH_MEMBER.FAILURE, { error }),
};

const loadProject = {
	request: (projectId) => createAction(LOAD_PROJECT.REQUEST, { projectId }),
	success: (project) => createAction(LOAD_PROJECT.SUCCESS, { project }),
	failure: (error) => createAction(LOAD_PROJECT.FAILURE, { error }),
};

const createProject = {
	request: (project) => createAction(CREATE_PROJECT.REQUEST, { project }),
	success: (projects, project) => createAction(CREATE_PROJECT.SUCCESS, { projects, project }),
	failure: (error) => createAction(CREATE_PROJECT.FAILURE, { error }),
};

const updateProject = {
	request: (projectId, project) => createAction(UPDATE_PROJECT.REQUEST, { projectId, project }),
	success: (projects, project) => createAction(UPDATE_PROJECT.SUCCESS, { projects, project }),
	failure: (error) => createAction(UPDATE_PROJECT.FAILURE, { error }),
};

const deleteProject = {
	request: (projectId) => createAction(DELETE_PROJECT.REQUEST, { projectId }),
	success: (projects) => createAction(DELETE_PROJECT.SUCCESS, { projects }),
	failure: (error) => createAction(DELETE_PROJECT.FAILURE, { error }),
};

export {
	LOAD_PROJECTS,
	loadProjects,
	LOAD_PROJECTS_WITH_MEMBER,
	loadProjectsWithMember,
	LOAD_PROJECT,
	loadProject,
	CREATE_PROJECT,
	createProject,
	UPDATE_PROJECT,
	updateProject,
	DELETE_PROJECT,
	deleteProject,
};
