/* eslint-disable no-unused-vars */
import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
	SET_SEARCH_RESULT,
	RESET_SEARCH,
	SET_FILTER_RESULT,
	RESET_FILTER,
	setSearchOrFilterResult,
	resetSearchAndFilter,
} from '../actions/utilitiesActions';

function* mergedSearchOrFilter() {
	const { search, filter } = yield select(({ utilitiesReducer }) => utilitiesReducer);
	const { isSearch, searchResult } = search;
	const { isFilter, filterResult } = filter;

	const getFinalData = () => {
		if (isSearch && isFilter) {
			// console.log('isSearch && isFilter');
			return [searchResult, filterResult].reduce((acc, curr) => curr.filter(Set.prototype.has, new Set(acc)));
		} else if (isSearch) {
			// console.log('isSearch');
			return searchResult;
		} else if (isFilter) {
			// console.log('isFilter');
			return filterResult;
		} else return null;
	};

	const FinalData = getFinalData();
	// console.log('FinalData', FinalData);
	if (Array.isArray(FinalData)) {
		yield put(setSearchOrFilterResult(FinalData));
	} else {
		yield put(resetSearchAndFilter());
	}
}

export default function* watchSearchOrFilter() {
	yield all([
		takeEvery(SET_SEARCH_RESULT, mergedSearchOrFilter),
		takeEvery(SET_FILTER_RESULT, mergedSearchOrFilter),
		takeEvery(RESET_SEARCH, mergedSearchOrFilter),
		takeEvery(RESET_FILTER, mergedSearchOrFilter),
	]);
}
