import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// Import reducer
import projectReducer from './projectReducer';
import deviceReducer from './deviceReducer';
import membershipReducer from './membershipReducer';
import utilitiesReducer from './utilitiesReducer';
import feedReducer from './feedReducer';
import billingAccountReducer from './billingAccountReducer';
import userReducer from './userReducer';
import authReducer from './authReducer';
import appSettingReducer from './appSettingReducer';
import hookReducer from './hookReducer'

export const DESTROY_SESSION = 'DESTROY_SESSION';

const appReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		projectReducer,
		deviceReducer,
		membershipReducer,
		utilitiesReducer,
		feedReducer,
		billingAccountReducer,
		userReducer,
		authReducer,
		appSettingReducer,
		hookReducer,
	});

const rootReducer = (history) => (state, action) => {
	if (action.type === DESTROY_SESSION) state = undefined;

	return appReducer(history)(state, action);
};

export default rootReducer;
