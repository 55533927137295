import { all, put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from 'redux/actions/membershipAction';
import { apolloQuery, apolloMutation } from 'utils/apollo';
import {
	QUERY_MEMBERSHIP_LIST,
	CREATE_MEMBERSHIP,
	UPDATE_MEMBERSHIP,
	DELETE_MEMBERSHIP,
	LEAVE_PROJECT,
} from 'utils/apollo/graphql/membership';
import { QUERY_USER } from 'utils/apollo/graphql/user';
import { updateUser } from 'redux/actions/userActions';
import { push } from 'connected-react-router';
import { parseMemberLevel } from 'utils/ParseData';

function* loadMemberships(action) {
	try {
		const { projectId } = action.payload;
		const { data, errors } = yield call(
			async () => await apolloQuery(QUERY_MEMBERSHIP_LIST, { projectid: projectId })
		);
		if (errors) throw errors;
		if (!data) throw new Error('data not found');
		const members = [];

		yield* data.membership.map(function* (member) {
			// get user by user id
			const userQuery = yield call(async () => await apolloQuery(QUERY_USER, { userid: member.userid }));
			if (userQuery.errors) throw userQuery.errors;
			const fullname = userQuery?.data?.userByUserID?.profile?.fullname;

			members.push({ ...member, fullname });
		});

		yield put(actions.loadMemberships.success(members));

		// update user level
		const prevUser = yield select(({ userReducer }) => userReducer.user);
		const user = data.membership.find((x) => x.userid === prevUser?.userid);
		const newUser = { ...prevUser, level: parseMemberLevel(user?.level) || null };

		if (prevUser?.level !== newUser?.level) yield put(updateUser.success(newUser));
	} catch (error) {
		yield put(actions.loadMemberships.failure(error));
	}
}

function* createMembership(action) {
	try {
		const { membership } = action.payload;
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(CREATE_MEMBERSHIP, {
					projectid: membership.projectid,
					username: membership.username,
					level: membership.level,
				})
		);

		// query new member name
		const userQuery = yield call(
			async () => await apolloQuery(QUERY_USER, { userid: data.createMembership.userid })
		);
		if (userQuery.errors) throw userQuery.errors;
		const fullname = userQuery?.data?.userByUserID?.profile?.fullname;

		if (errors) {
			throw errors;
		} else {
			const prevMembers = yield select(({ membershipReducer }) => membershipReducer.memberships);
			const newMembers = [...prevMembers, { ...data.createMembership, fullname, level: parseMemberLevel(membership.level) }];
			yield put(actions.createMembership.success(newMembers));
		}
	} catch (error) {
		yield put(actions.createMembership.failure(error));
	}
}

function* updateMembership(action) {
	try {
		const { membership } = action.payload;
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(UPDATE_MEMBERSHIP, {
					projectid: membership.projectid,
					username: membership.username,
					level: membership.level,
				})
		);
		if (errors) {
			throw errors;
		} else {
			const prevMembers = yield select(({ membershipReducer }) => membershipReducer.memberships);
			const updatedMembers = prevMembers.map((member) => {
				return member.username === data.updateMembership.username
					? { ...data.updateMembership, fullname: member.fullname }
					: member;
			});
			yield put(actions.updateMembership.success(updatedMembers));
		}
	} catch (error) {
		yield put(actions.updateMembership.failure(error));
	}
}

function* deleteMembership(action) {
	try {
		const { projectId, username } = action.payload;
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(DELETE_MEMBERSHIP, {
					projectid: projectId,
					username: username,
				})
		);
		if (errors) {
			throw errors;
		} else {
			yield put(actions.deleteMembership.success(data.deleteMembership));
		}
	} catch (error) {
		yield put(actions.deleteMembership.failure(error));
	}
}

function* leaveProject(action) {
	try {
		const { projectId } = action.payload;
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(LEAVE_PROJECT, {
					projectid: projectId,
				})
		);
		// console.log('leaveProject', data, errors);
		if (errors) {
			throw errors;
		} else {
			if (data.leaveProject) {
				yield put(actions.leaveProject.success(data.leaveProject));
				yield put(push('/'));
			} else {
				yield put(actions.leaveProject.failure({ message: 'Something wrong' }));
			}
		}
	} catch (error) {
		yield put(actions.leaveProject.failure(error));
	}
}

export default function* watchMemberships() {
	yield all([
		takeLatest(actions.LOAD_MEMBERSHIPS.REQUEST, loadMemberships),
		takeLatest(actions.CREATE_MEMBERSHIP.REQUEST, createMembership),
		takeLatest(actions.UPDATE_MEMBERSHIP.REQUEST, updateMembership),
		takeLatest(actions.DELETE_MEMBERSHIP.REQUEST, deleteMembership),
		takeLatest(actions.LEAVE_PROJECT.REQUEST, leaveProject),
	]);
}
