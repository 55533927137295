import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import dotenv from "dotenv";
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

ReactDOM.render(<App />, document.getElementById("root"));

dotenv.config();

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const user_tz = dayjs.tz.guess();
dayjs.tz.setDefault(user_tz);
