import React, { Component } from 'react';
import { Result, Button } from 'antd';

class ErrorBoundary extends Component {
	state = {
		errorMessage: '',
	};
	static getDerivedStateFromError(error) {
		return { errorMessage: error.toString() };
	}

	render() {
		if (this.state.errorMessage) {
			return (
				<div className="centerFullScreen">
					<Result
						status="error"
						title="เกิดปัญหาบางอย่างระหว่างการดำเนินการ"
						extra={
							<a href="/">
								<Button type="primary">กลับหน้าหลัก</Button>
							</a>
						}
					/>
				</div>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
