import { all, spawn, call } from 'redux-saga/effects';

// import sagas
import { appInitialize, watchSignOut } from './authSaga';
import watchProjects from './projectSaga';
import watchDevices from './deviceSaga';
import watchMemberships from './membershipSaga';
import watchFeed from './feedSaga';
import watchBillingAccount from './billingAccountSagas';
import watchUser from './userSagas';
import watchSearchOrFilter from './utilitiesSagas';
import watchHook from './hookSaga'

export default function* rootSagas() {
	yield all([
		call(appInitialize),
		spawn(watchProjects),
		spawn(watchDevices),
		spawn(watchMemberships),
		spawn(watchFeed),
		spawn(watchSearchOrFilter),
		spawn(watchBillingAccount),
		spawn(watchUser),
		spawn(watchSignOut),
		spawn(watchHook)
	]);
}
