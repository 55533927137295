import { createTypes, createAction } from 'utils/createActionHelper';

const LOAD_BILLING_ACCOUNT = createTypes('get', 'billing_account');
const LOAD_PROJECT_BILLING_ACCOUNT = createTypes('get', 'project_billing_account');


const loadBillingAccount = {
	request: (userid) => createAction(LOAD_BILLING_ACCOUNT.REQUEST, { userid }),
	success: (billingAccountData) => createAction(LOAD_BILLING_ACCOUNT.SUCCESS, { billingAccountData }),
	failure: (error) => createAction(LOAD_BILLING_ACCOUNT.FAILURE, { error }),
};

const loadProjectBillingAccount = {
	request: (projectid) => createAction(LOAD_PROJECT_BILLING_ACCOUNT.REQUEST, { projectid }),
	success: billingAccountData => createAction(LOAD_PROJECT_BILLING_ACCOUNT.SUCCESS, { billingAccountData }),
	failure: error => createAction(LOAD_PROJECT_BILLING_ACCOUNT.FAILURE, { error }),
}

export { LOAD_BILLING_ACCOUNT, loadBillingAccount, LOAD_PROJECT_BILLING_ACCOUNT, loadProjectBillingAccount };
