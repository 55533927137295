/* eslint-disable no-unused-vars */
import { all, put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from 'redux/actions/billingAccountActions';
import { apolloQuery } from 'utils/apollo';
import {
	QUERY_BILLING_ACCOUNT_DATA,
	QUERY_MY_BILLING_ACCOUNT,
	QUERY_USAGE_PAGE,
	QUERY_PROJECT_BILLING_USAGE,
} from 'utils/apollo/graphql/billingAccount';
import { QUERY_MEMBERSHIP_LIST } from 'utils/apollo/graphql/membership';
import { QUERY_DEVICE_LIST } from 'utils/apollo/graphql/device';

function* loadBillingAccount(action) {
	try {
		const { userid } = action.payload;
		const myBilling = yield call(async () => await apolloQuery(QUERY_MY_BILLING_ACCOUNT));
		if (myBilling.errors) throw myBilling.errors;

		const { data, errors } = yield call(
			async () =>
				await apolloQuery(QUERY_USAGE_PAGE, {
					billingaccountid: myBilling.data.MyBillingAccount[0].billingaccountid || null,
				})
		);

		const projects = [];
		const devices = [];

		yield* data.projectList.map(function* (project) {
			// get project membership
			const membershipQuery = yield call(
				async () => await apolloQuery(QUERY_MEMBERSHIP_LIST, { projectid: project.projectid })
			);

			if (membershipQuery.errors) throw membershipQuery.errors;

			const memberLevel = membershipQuery.data.membership.find((item) => item.userid === userid)?.level;
			if (memberLevel === 5 && project.tags?.A === 'handysense') {
				const deviceListQuery = yield call(
					async () =>
						await apolloQuery(QUERY_DEVICE_LIST, {
							filter: { projectid: project.projectid, tags: '{A:handysense}' },
						})
				);
				if (deviceListQuery.errors) throw deviceListQuery.errors;
				deviceListQuery.data.deviceList.forEach((device) => {
					devices.push(device);
				});
			}

			projects.push({ ...project, level: memberLevel });
		});

		if (errors) {
			throw errors;
		} else {
			yield put(
				actions.loadBillingAccount.success({
					billingAccount: {
						resourceUsage: data.BillingAccountResourceUsageProfile,
						metricUsage: data.BillingMetricUsageProfile,
						subscription: data.BillingAccountSubscriptionProfile,
					},
					projects,
					devices,
				})
			);
		}
	} catch (error) {
		console.log(error);
		yield put(actions.loadBillingAccount.failure(error));
	}
}

function* loadProjectBillingAccount(action) {
	try {
		if (!action.payload.projectid) {
			yield put(actions.loadProjectBillingAccount.success(null));
		} else {
			const queryUsagePage = yield call(
				async () => await apolloQuery(QUERY_PROJECT_BILLING_USAGE, { projectid: action.payload.projectid })
			);
			const { ProjectBillingAccountSubscriptionProfile, ProjectBillingMetricUsageProfile } = queryUsagePage.data;

			const DATA = {
				startBillDate: ProjectBillingAccountSubscriptionProfile.current_cycle_start_at,
				endBillDate: ProjectBillingAccountSubscriptionProfile.current_cycle_end_at,
				package: ProjectBillingAccountSubscriptionProfile.packagename,

				ProjectBillingMetricUsageProfile,
			};
			if (queryUsagePage.errors) {
				throw queryUsagePage.errors;
			} else {
				yield put(actions.loadProjectBillingAccount.success(DATA));
			}
		}
	} catch (error) {
		console.log(error);
		yield put(actions.loadProjectBillingAccount.failure(error));
	}
}

export default function* watchBillingAccount() {
	yield all([
		takeLatest(actions.LOAD_BILLING_ACCOUNT.REQUEST, loadBillingAccount),
		takeLatest(actions.LOAD_PROJECT_BILLING_ACCOUNT.REQUEST, loadProjectBillingAccount),
	]);
}
